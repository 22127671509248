import NavTitle from "../utils/NavTitle";

export default function Support() {
  return (
    <>
      <NavTitle showBack>Support</NavTitle>
      <p>Todo FAQ</p>
    </>
  );
}
