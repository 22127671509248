// Libraries import
import { useState } from 'react'
import axios, { AxiosRequestConfig, AxiosError } from 'axios'

// useAxiosPost : return tools to send GET request and handle error messages
export const useAxiosGet = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  // axiosGetRequest : send GET request and handle error cases
  interface CustomAxiosConfig extends AxiosRequestConfig {
    signal?: AbortSignal
  }
  const axiosGetRequest = async <T>(
    URL: string,
    config?: CustomAxiosConfig | undefined
  ): Promise<T | void> => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const response = await axios.get<T>(URL, config)
      // client received an successfull response (2xx)
      if (response.status.toString()[0] === '2') {
        setIsLoading(false)
        setErrorMessage('')
        return response.data
      }
      setIsLoading(false)
      setErrorMessage('Une erreur est survenue, merci de réessayer.')
      return
    } catch (error: unknown) {
      const axiosError = error as AxiosError<any>
      // client received an error response (5xx, 4xx)
      if (axiosError.response) {
        setErrorMessage(
          axiosError.response?.data?.message ||
            'Une erreur est survenue coté serveur.'
        )
        setIsLoading(false)
        return
      } else if (axiosError.request) {
        // client never received a response, or request never left
        if (typeof axiosError.request.data === 'string') {
          setErrorMessage(axiosError.request.data)
        } else {
          setErrorMessage('Erreur serveur, merci de réessayer.')
        }
      } else {
        setErrorMessage('Une erreur est survenue, merci de réessayer.')
      }
      setIsLoading(false)
    }
  }

  return { axiosGetRequest, isLoading, errorMessage, setErrorMessage }
}
