import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import { useEffect, useState } from "react";

export interface NumericInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
  unit: string;
}

export function NumericInput({ value, onChange, unit }: NumericInputProps) {
  const [strValue, setStrValue] = useState(
    value === null ? "" : value.toString()
  );
  const showValidateButton =
    value !== parseInt(strValue) && !(value === null && strValue === "");
  const disableValidateButton = isNaN(parseInt(strValue)) && strValue !== "";
  useEffect(() => {
    setStrValue(value === null ? "" : value.toString());
  }, [value]);
  return (
    <div className="d-flex">
      <InputGroup style={{ width: "unset" }}>
        <Input
          type="number"
          style={{ flexGrow: 0, width: "10em" }}
          value={strValue}
          onChange={(e) => {
            setStrValue(e.target.value);
          }}
        />
        <InputGroupText>{unit}</InputGroupText>
      </InputGroup>
      {showValidateButton && (
        <Button
          outline
          color="secondary"
          className="ms-2"
          disabled={disableValidateButton}
          onClick={() => {
            onChange(strValue === "" ? null : parseInt(strValue));
          }}
        >
          Valider
        </Button>
      )}
    </div>
  );
}
