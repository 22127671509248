import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Navigate } from "react-router";
import { NavTitle } from "../utils/NavTitle";
import { useDebugAction } from "../utils/debug";
import { useMsal } from "@azure/msal-react";
import { useMicrosoftGraph } from "../MicrosoftGraphProvider";
import { UncontrolledAlert } from "reactstrap";

export default function Auth() {
  const { instance, inProgress } = useMsal();
  const { userProfile, isLoadingFetchUserProfile, errorMessage } =
    useMicrosoftGraph();

  const signingIn = inProgress === "login";

  useDebugAction({
    label: "[dev] debug auth",
    onClick() {
      window.location.href = `${process.env.REACT_APP_EERO_URL}/darty?debug=1`;
    },
  });

  useDebugAction({
    label: "[dev] flush",
    onClick() {
      window.location.href = `${process.env.REACT_APP_EERO_URL}/darty?flush=1`;
    },
  });

  if (userProfile) return <Navigate to="/" />;

  return (
    <>
      <NavTitle>Connexion utilisateur</NavTitle>
      {!!errorMessage && (
        <UncontrolledAlert color="warning" className="text-center">
          {errorMessage}
        </UncontrolledAlert>
      )}
      <div
        className="d-flex flex-column align-items-center"
        style={{ marginTop: "10vh" }}
      >
        <CTAButton
          name="Se connecter"
          category="primary"
          onClick={() => {
            instance.loginRedirect();
          }}
          isLoading={signingIn || isLoadingFetchUserProfile}
        />
      </div>
    </>
  );
}

export interface User {
  id: string;
  name: string;
  email: string;
  isManager: boolean;
}
