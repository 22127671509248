import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Button, Table } from "reactstrap";
import Copy from "../../assets/images/copy.svg?component";
import questionary from "../../config/questionary";
import { CUSTOM_SUMMARIES } from "./EligibilityForm/customInputs";
import { Appointment } from "../MyAppointments/types";

export default function AppointmentSummary() {
  const {
    state: { appointment, contactForm, answers },
  } = useLocation();
  const { lead_id, created_at, name } = appointment as Appointment;
  const {
    leadEmail,
    leadPhone,
    leadNote,
    address,
    departement,
    zipCode,
    isDartyMax,
  } = contactForm;
  const navigate = useNavigate();
  return (
    <>
      <h2
        className="text-center mt-5 pt-4"
        style={{
          opacity: 0,
          animation: "fade-in-top 0.5s ease-out 0.3s forwards",
        }}
      >
        Votre rendez-vous :
      </h2>
      <div
        style={{
          opacity: 0,
          animation: "fade-in-top 0.5s ease-out 0.9s forwards",
        }}
        className="mt-5"
      >
        <Table style={{ overflow: "hidden" }}>
          <tbody>
            <tr style={{ verticalAlign: "middle" }}>
              <th scope="row">Référence :</th>
              <td
                onClick={() => {
                  const copyFeedback = document.getElementById("copyFeedback")!;
                  if (copyFeedback.classList.contains("active")) {
                    return;
                  }
                  navigator.clipboard.writeText(lead_id);
                  copyFeedback.classList.add("active");
                  setTimeout(() => {
                    copyFeedback.classList.remove("active");
                  }, 600);
                }}
              >
                <span>{lead_id}</span>
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  className="ms-4"
                  style={{ pointerEvents: "none" }}
                >
                  <Copy />
                </Button>
                <span id="copyFeedback" className="copy-feedback">
                  Copié !
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">Date :</th>
              <td>{moment(created_at).format("DD/MM/YYYY HH[h]mm")}</td>
            </tr>
            {/* <tr>
              <th scope="row">Votre interlocuteur :</th>
              <td>{staffMemberName}</td>
            </tr> */}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          opacity: 0,
          animation: "fade-in-top 0.5s ease-out 1.4s forwards",
        }}
        className="mt-5 d-flex flex-column align-items-center"
      >
        <CTAButton
          name="Retour à l'accueil"
          category="secondary"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div
        style={{
          opacity: 0,
          animation: "fade-in-top 1s ease-out 2.3s forwards",
          marginTop: 120,
          fontSize: "0.9em",
        }}
      >
        <div
          className="text-center text-uppercase mb-3"
          style={{ opacity: 0.7 }}
        >
          Vos informations :
        </div>
        <Table style={{ overflow: "hidden", opacity: 0.7 }}>
          <tbody>
            <tr>
              <th scope="row">Nom :</th>
              <td>{name}</td>
            </tr>
            <tr>
              <th scope="row">Téléphone :</th>
              <td>{leadPhone}</td>
            </tr>
            <tr>
              <th scope="row">Email :</th>
              <td>
                <div style={{ lineBreak: "anywhere" }}>{leadEmail}</div>
                {!!isDartyMax && (
                  <div
                    className="mt-1 text-dark-emphasis"
                    style={{ fontSize: "0.85em" }}
                  >
                    <span style={{ fontSize: "0.8em" }}>✅</span> Darty Max
                  </div>
                )}
              </td>
            </tr>
            {(zipCode || departement) && (
              <tr>
                <th scope="row">Adresse :</th>
                <td>
                  <div>{address}</div>
                  <div>
                    {zipCode} {departement}
                  </div>
                </td>
              </tr>
            )}
            {!!leadNote && (
              <tr>
                <th scope="row">Notes :</th>
                <td>{leadNote}</td>
              </tr>
            )}
            {questionary.questions.map(
              ({ id, options, summary_text: summaryText }) => (
                <tr key={id}>
                  <th scope="row">{summaryText} :</th>
                  <td>
                    {options[0].custom_input
                      ? (() => {
                          const CustomSummaryComponent =
                            CUSTOM_SUMMARIES[options[0].custom_input];
                          return (
                            <CustomSummaryComponent>
                              {answers[id]}
                            </CustomSummaryComponent>
                          );
                        })()
                      : options.find(
                          ({ id: optionId }) => optionId === answers[id]
                        )!.text}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}
