//Librairies import
import { useState } from "react";
import moment from "moment";

//Services import
import { useCreateAppointment as _useCreateAppointment } from "./bookingHooks/useCreateAppointment";
import {
  IFormatStaffAvailabilityReturn,
  useGetStaffAvailability,
} from "./bookingHooks/useGetStaffAvailability";

import { PrendreRendezVous } from "./PrendreRendezVous/PrendreRendezVous";

// Images import
import { useDebugAction } from "../../../utils/debug";
import { useMain } from "../../../MainProvider";
import { Navigate, useLocation, useNavigate } from "react-router";
import { MOCK_BOOKING } from "../../../config/flags";
import {
  getDefaultDateRange,
  getMyAppointments,
  stringifyDateRange,
} from "../../MyAppointments";
import { Appointment } from "../../MyAppointments/types";
import { REST_CACHE, clearCache, writeCache } from "../../../utils/restCache";
import { useMicrosoftGraph } from "../../../MicrosoftGraphProvider";

const AppointmentForm = () => {
  //CustomHook import
  const { getStaffAvailability, isLoading: getStaffAvailabilityIsLoading } =
    useGetStaffAvailability();

  let { createAppointment, isLoading: createBookingisLoading } =
    useCreateAppointment();

  const navigate = useNavigate();
  const { user, store } = useMain();
  const { userProfile } = useMicrosoftGraph();
  const { pathname, state } = useLocation();

  //Form usestate
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedHour, setSelectedHour] = useState<IOption | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [userFirstName, setUserFirstName] = useState<string | null>(null);
  const [userLastName, setUserLastName] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userPhone, setUserPhone] = useState<string | null>(null);
  const [userNotes, setUserNotes] = useState<string | null>(null);
  const [availableHours, setAvailableHours] = useState<IOption[]>([]);
  const [membershipCode, setMembershipCode] = useState<string | null>(null);
  const [address, setAddress] = useState<{
    address?: string;
    latitude?: number;
    longitude?: number;
    zipCode?: string;
    departement?: string;
  } | null>(null);
  const [isDartyMax, setIsDartyMax] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>("");

  const [fetchingNewAppointment, setFetchingNewAppointment] = useState(false);

  const [isPostBookingSuccessful, setIsPostBookingSuccessful] =
    useState<boolean>(false);

  // const { getMyAppointments } = useGetMyAppointments();

  const onFormSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setErrorMessage(null);

    if (
      userEmail &&
      userFirstName &&
      userLastName &&
      userPhone &&
      isChecked &&
      selectedDate &&
      selectedHour
    ) {
      const selectedDateFormated = moment(selectedDate as Date)
        .format("YYYY-MM-DD")
        .toString();

      //Second check before calling update/create appointment
      const staffAvailability: IFormatStaffAvailabilityReturn[] =
        await getStaffAvailability({ selectedDate: selectedDateFormated });
      const availabilitySelected = staffAvailability.find(
        (availability) => availability.startTimeSlot === selectedHour.value
      );

      // Pick a random commercial in the list for the date + hour selected
      const staffMember =
        availabilitySelected?.staffMembersAvailables[
          Math.floor(
            Math.random() * availabilitySelected.staffMembersAvailables.length
          )
        ];

      const { appointmentID: appointmentIDResponse } = await createAppointment({
        dateTime: `${selectedDateFormated}T${selectedHour.label}:00`,
        staffMemberIds: staffMember || "",
        leadName: `${userFirstName} ${userLastName}`,
        leadEmail: userEmail.replace(/\s+/g, ""), //regex suppress white spaces
        leadPhone: userPhone,
        leadNote: userNotes,
        address: address?.address,
        departement: address?.departement,
        latitude: address?.latitude,
        longitude: address?.longitude,
        zipCode: address?.zipCode,
        isDartyMax,
        partnerName: "darty",
        partnerUserId: userProfile?.employeeId || undefined,
        partnerUserName: user?.name,
        partnerUserEmail: user?.email,
        partnerStoreId: store?.id,
        partnerStoreName: store?.name,
      });

      if (!appointmentIDResponse) {
        setIsPostBookingSuccessful(false);
        setErrorMessage("Impossible de créer un rendez vous.");
        return;
      }
      for (const key in REST_CACHE) {
        if (key.match(/^myAppointments/)) {
          clearCache(key);
        }
      }
      if ("sellerStats" in REST_CACHE) {
        REST_CACHE.sellerStats.value.nb_rendez_vous++;
      }
      let appointments: Appointment[] = [];
      try {
        setFetchingNewAppointment(true);
        appointments = (await getMyAppointments({
          userId: userProfile?.employeeId,
          storeId: store!.id,
          dateRange: getDefaultDateRange(),
        }))!;
      } catch (e) {
        console.error(e);
      } finally {
        setFetchingNewAppointment(false);
      }

      const cacheKey = `myAppointments(${stringifyDateRange(
        getDefaultDateRange()
      )})`;
      appointments = [
        {
          lead_id: appointmentIDResponse,
          vendor_id: userProfile?.employeeId || undefined,
          store_id: store!.id,
          name: `${userFirstName} ${userLastName}`,
          created_at: moment().format("YYYY-MM-DD"),
          lead_status: {
            rendez_vous: {
              date: `${selectedDateFormated}T${selectedHour.label}:00Z`,
              status: "planned",
            },
            etude_energetique: {
              date: null,
              status: "not_started",
            },
            vente: {
              date: null,
              status: "not_started",
            },
            installation: {
              date: null,
              status: "not_started",
            },
          },
        },
        ...appointments,
      ];
      writeCache(cacheKey, appointments);

      const appointment = appointments.find(
        ({ lead_id }) => lead_id === appointmentIDResponse
      );

      window.scrollTo({
        top: 0,
      });

      if (!appointment) {
        console.error(
          `Couldn't find appointment with id ${appointmentIDResponse} in fetched appointments`
        );
        navigate(
          `/my-appointments?focus_appointment=${encodeURIComponent(
            appointmentIDResponse
          )}`
        );
        return;
      }

      const contactForm = {
        staffMemberIds: staffMember || "",
        leadName: `${userFirstName} ${userLastName}`,
        leadEmail: userEmail.replace(/\s+/g, ""), //regex suppress white spaces
        leadPhone: userPhone,
        leadNote: userNotes,
        address: address?.address,
        departement: address?.departement,
        zipCode: address?.zipCode,
        isDartyMax,
      };

      navigate(pathname, {
        state: { ...state, appointment, contactForm },
      });
    } else {
      setErrorMessage("Champs obligatoires manquants");
    }
  };

  const getStaffAvailabilityFunction = async ({
    selectedDate,
  }: {
    selectedDate: Date | [Date, Date] | null;
  }) => {
    const selectedDateFormated = moment(selectedDate as Date)
      .format("YYYY-MM-DD")
      .toString();

    const staffAvailability: IFormatStaffAvailabilityReturn[] =
      await getStaffAvailability({ selectedDate: selectedDateFormated });

    setAvailableHours(() =>
      staffAvailability.map((availability) => {
        return {
          label: availability.startTimeSlot,
          value: availability.startTimeSlot,
        };
      })
    );
  };

  useDebugAction({
    label: "[dev] auto fill",
    onClick() {
      setIsChecked(true);
      setUserFirstName("Ugo");
      setUserLastName("CARRASCO");
      setUserEmail("carrasco.ugo@gmail.com");
      setUserPhone("0642489726");
    },
  });

  if (!user || !store) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      {!isPostBookingSuccessful && (
        <PrendreRendezVous
          userEmail={userEmail}
          userFirstName={userFirstName}
          userLastName={userLastName}
          userNotes={userNotes}
          userPhone={userPhone}
          setUserEmail={setUserEmail}
          setUserFirstName={setUserFirstName}
          setUserLastName={setUserLastName}
          setUserNotes={setUserNotes}
          setUserPhone={setUserPhone}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedHour={selectedHour}
          setSelectedHour={setSelectedHour}
          isChecked={isChecked}
          availableHours={availableHours}
          onFormSubmit={onFormSubmit}
          setIsChecked={setIsChecked}
          errorMessage={errorMessage}
          getStaffAvailabilityFunction={getStaffAvailabilityFunction}
          getStaffAvailabilityIsLoading={getStaffAvailabilityIsLoading}
          createBookingisLoading={createBookingisLoading}
          pageBranding="darty"
          setAddress={setAddress}
          isDartyMax={isDartyMax}
          setIsDartyMax={setIsDartyMax}
          membershipCode={membershipCode}
          setMembershipCode={setMembershipCode}
          fetchingNewAppointment={fetchingNewAppointment}
        />
      )}
    </>
  );
};

function useMockedCreateAppointment() {
  const [isLoading, setIsLoading] = useState(false);
  const createAppointment = async function () {
    setIsLoading(true);
    await new Promise((resolve) => {
      setTimeout(resolve, 300);
    });
    setIsLoading(false);
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const randomPart = Math.floor(Math.random() * 1000); // Random number between 0 and 999
    const numberPart = (timestamp.toString() + randomPart.toString()).slice(
      0,
      9
    );
    return {
      appointmentID: `TUC${numberPart}`,
    };
  };
  return {
    createAppointment,
    isLoading,
  };
}

const useCreateAppointment = MOCK_BOOKING
  ? useMockedCreateAppointment
  : _useCreateAppointment;

interface IOption {
  label: string;
  value: string;
}

export default AppointmentForm;
