import { ComponentPropsWithoutRef, useEffect, useRef, useState } from "react";
import { ChoixUnique } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixUnique/ChoixUnique";
import cn from "classnames";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { DebugAction } from "../../../utils/debug";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useMain } from "../../../MainProvider";
import { smoothScrollToElement } from "../../NewAppointment/AppointmentForm/PrendreRendezVous/PrendreRendezVous";
import CUSTOM_INPUTS from "./customInputs";
import questionary, { Answers } from "../../../config/questionary";

export default function EligibilityForm() {
  const [answers, setAnswers] = useState<Answers>(
    Object.fromEntries(questionary.questions.map(({ id }) => [id, null]))
  );
  const [maxProgress, setMaxProgress] = useState(0);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (maxProgress > 0) {
      smoothScrollToElement({
        fromEl: document.querySelector<HTMLElement>(
          `[data-item-index="${maxProgress - 1}"]`
        )!,
        elementSelector: `[data-item-index="${maxProgress}"]`,
        container: questionaryDiv.current!,
      });
    }
  }, [maxProgress]);

  const questionaryDiv = useRef<HTMLElement>();

  const { user, store } = useMain();

  if (!user || !store) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div
        ref={(el) => {
          questionaryDiv.current = el || undefined;
        }}
      >
        <DebugAction
          onClick={() => {
            setAnswers(getRandomAnswers());
            setMaxProgress(questionary.questions.length);
          }}
        >
          [dev] fill form
        </DebugAction>
        <DebugAction
          onClick={() => {
            navigate(pathname, { state: { answers: getRandomAnswers() } });
          }}
        >
          [dev] skip form
        </DebugAction>
        {questionary.questions.map(
          ({ id: questionId, text, options }, questionIndex) => {
            const isCustomInputAnswer =
              options.length === 1 && "custom_input" in options[0];
            const CustomInputComponent = isCustomInputAnswer
              ? CUSTOM_INPUTS[options[0].custom_input!]
              : null;
            return (
              <ProgressiveShow
                key={questionId}
                itemIndex={questionIndex}
                maxProgress={maxProgress}
              >
                <div className="lead mb-3">{text}</div>
                {isCustomInputAnswer ? (
                  <CustomInputComponent
                    value={answers[questionId]}
                    onChange={(value: any) => {
                      setMaxProgress(Math.max(maxProgress, questionIndex + 1));
                      setAnswers({
                        ...answers,
                        [questionId]: value,
                      });
                    }}
                  />
                ) : (
                  <>
                    <ChoixUnique
                      optionsList={options.map(({ id, text }) => ({
                        value: id,
                        label: text,
                      }))}
                      optionSelected={
                        answers[questionId]
                          ? {
                              value: answers[questionId]!,
                              label: options.find(
                                ({ id }) => id === answers[questionId]
                              )!.text,
                            }
                          : undefined
                      }
                      gap
                      onSelect={({ value, label }) => {
                        const id = value;

                        const selectedOption =
                          id && getOption(questionId, value);
                        if (!selectedOption || !selectedOption.ineligible) {
                          setMaxProgress(
                            Math.max(maxProgress, questionIndex + 1)
                          );
                        }
                        setAnswers({
                          ...answers,
                          [questionId]: answers[questionId] === id ? null : id,
                        });
                      }}
                    />
                    {(() => {
                      if (!answers[questionId]) return;
                      const selectedOption = getOption(
                        questionId,
                        answers[questionId]!
                      );
                      if (selectedOption.ineligible) {
                        return (
                          <div className="mt-3">
                            <div className="text-danger">
                              {selectedOption.ineligible_reason}
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                              <CTAButton
                                size="M"
                                name="Retour à l'accueil"
                                category="secondary"
                                onClick={() => {
                                  navigate("/");
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </>
                )}
              </ProgressiveShow>
            );
          }
        )}
        <ProgressiveShow
          itemIndex={questionary.questions.length}
          maxProgress={maxProgress}
          className="px-3 d-flex justify-content-center"
        >
          <CTAButton
            name="Continuer"
            category="primary"
            family="vitee"
            onClick={() => {
              navigate(pathname, { state: { answers } });
            }}
            isDisabled={Object.entries(answers).some(([questionId, answer]) => {
              if (answer === null) return true;
              const question = questionary.questions.find(
                ({ id }) => id === questionId
              )!;
              if (!question.options[0].custom_input) {
                return getOption(questionId, answer).ineligible;
              }
              return false;
            })}
          />
        </ProgressiveShow>
      </div>
    </>
  );
}

function getRandomAnswers() {
  return {
    ...Object.fromEntries(
      questionary.questions.map(({ id, options }) => [
        id,
        "custom_input" in options[0]
          ? (123 as unknown as string)
          : options[Math.floor(Math.random() * options.length)].id,
      ])
    ),
    proprio: "proprietaire",
    logement_type: "maison",
    consommation_energie: (500 +
      Math.floor(Math.random() * 1500)) as unknown as string,
    surface: (20 + Math.floor(Math.random() * 150)) as unknown as string,
  };
}

function getOption(questionId: string, optionId: string) {
  return questionary.questions
    .find(({ id }) => id === questionId)!
    .options.find(({ id }) => id === optionId)!;
}

function ProgressiveShow({
  itemIndex,
  maxProgress,
  className,
  style,
  ...props
}: {
  itemIndex: number;
  maxProgress: number;
} & ComponentPropsWithoutRef<"div">) {
  let opacity = 1;
  if (itemIndex > maxProgress) opacity = 0.2;
  let display = "block";
  if (itemIndex > maxProgress + 1) display = "none";
  const pointerEvents = itemIndex > maxProgress ? "none" : undefined;
  return (
    <div
      className={cn("my-5", className)}
      style={{ opacity, pointerEvents, display, ...style }}
      data-item-index={itemIndex}
      {...props}
    />
  );
}
