import { useCallback, useMemo, useState } from "react";
import { DebugActionConfig } from "./types";
import { DebugContext } from "./DebugContext";

export function DebugProvider({ children }: { children: React.ReactNode }) {
  const [debugActions, setDebugActions] = useState<DebugActionConfig[]>([]);
  const registerDebugAction = useCallback(
    ({ label, onClickRef }: DebugActionConfig) => {
      const debugAction = { label, onClickRef };
      setDebugActions((debugActions) => [...debugActions, debugAction]);
      return debugAction;
    },
    []
  );
  const unregisterDebugAction = useCallback(
    (debugAction: DebugActionConfig) => {
      setDebugActions((debugActions) =>
        debugActions.filter((da) => da !== debugAction)
      );
    },
    []
  );
  const ctx = useMemo(
    () => ({
      debugActions,
      registerDebugAction,
      unregisterDebugAction,
    }),
    [debugActions, registerDebugAction, unregisterDebugAction]
  );

  return <DebugContext.Provider value={ctx}>{children}</DebugContext.Provider>;
}
