import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import { useMain } from "../MainProvider";
import { Navigate, useNavigate } from "react-router";
import STORES from "../config/stores";
import { NavTitle } from "../utils/NavTitle";

export default function StoreChoice() {
  const [storeIdStr, setStoreIdStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { user, store, setStore } = useMain();
  const navigate = useNavigate();

  const submitStore = async (storeId: string) => {
    setLoading(true);
    try {
      const store = await getStore(storeId);
      setStore(store);
      navigate("/");
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  if (!user) return <Navigate replace to="/auth" />;
  return (
    <>
      <NavTitle showBack={!!store}>Selection du magasin</NavTitle>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitStore(storeIdStr);
        }}
        style={{ marginTop: "10vh" }}
      >
        <FormGroup className="mt-3">
          <Input
            type="select"
            name="store"
            id="store"
            value={storeIdStr}
            onChange={(e) => {
              setStoreIdStr(e.target.value);
            }}
          >
            <option value="">Choisissez un magasin...</option>
            {STORES.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="mt-5 d-flex justify-content-center">
          <CTAButton
            name="Continuer"
            category="primary"
            family="vitee"
            type="submit"
            isLoading={loading}
            isDisabled={storeIdStr === ""}
          />
        </FormGroup>
      </Form>
    </>
  );
}

export type StoreId = string;

export interface Store {
  id: StoreId;
  name: string;
}

async function getStore(storeId: StoreId) {
  const store = STORES.find(({ id }) => id === storeId.trim());
  if (store) {
    return store;
  } else throw new Error(`notFound`);
}
