export const REST_CACHE: Record<string, { value: any; timestamp: number }> = {};

if (process.env.NODE_ENV === "development") {
  Object.assign(window, { REST_CACHE });
}

export function writeCache(key: string, value: any) {
  REST_CACHE[key] = {
    value,
    timestamp: new Date().valueOf(),
  };
}

export function readCache(key: string, maxLength = 1000 * 60 * 10) {
  if (
    REST_CACHE[key] &&
    new Date().valueOf() - REST_CACHE[key].timestamp <= maxLength
  ) {
    return REST_CACHE[key].value;
  }
  return undefined;
}

export async function withCache<T>(
  key: string,
  getValue: () => T | Promise<T>,
  maxLength = 1000 * 60 * 10 // 10 minutes by default
): Promise<T> {
  const fromCache = readCache(key, maxLength);
  if (fromCache) return fromCache;
  const value = await getValue();
  writeCache(key, value);
  return value;
}

export function clearCache(key: string) {
  delete REST_CACHE[key];
}
