import { Routes, Route } from "react-router";
import { Container } from "reactstrap";
import Auth from "./pages/Auth";
import StoreChoice from "./pages/StoreChoice";
import Home from "./pages/Home";
import NewAppointment from "./pages/NewAppointment";
import MyAppointments from "./pages/MyAppointments";
import StoreSummary from "./pages/StoreSummary";
import Support from "./pages/Support";
import Logout from "./pages/Logout";
import { ENABLE_MANAGER_MODE } from "./config/flags";
import Layout from "./Layout";

function App() {
  return (
    <Layout>
      <Container className="mt-5 mb-5 px-3">
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/store-choice" element={<StoreChoice />} />
          <Route path="/" element={<Home />} />
          <Route path="/new-appointment" element={<NewAppointment />} />
          <Route path="/my-appointments" element={<MyAppointments />} />
          {ENABLE_MANAGER_MODE && (
            <Route path="/store-summary" element={<StoreSummary />} />
          )}
          <Route path="/support" element={<Support />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Container>
    </Layout>
  );
}

export default App;
