import { useDebugAction } from "./useDebugAction";
import { DebugActionHookArg } from "./types";

export function DebugAction({
  children,
  onClick,
}: {
  children: DebugActionHookArg["label"];
  onClick: DebugActionHookArg["onClick"];
}) {
  useDebugAction({
    label: children,
    onClick,
  });
  return null;
}
