import styles from './layout.module.scss'

// Libraries import
import React from 'react'

// Interfaces declaration
interface Props {
  children: React.ReactNode
}

export const Layout: React.FC<Props> = ({ children }) => {
  return <div className={styles.layout}>{children}</div>
}
