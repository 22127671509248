// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";
import { stylesVariables, DSColors } from "../../../../styles/variables";

interface Card {
  value: string | null;
  title: string | null;
  iconSrc?: string;
}

/**
 * pass a single card to use the single card style
 * pass a list of two cards to use the double cards style
 */
export interface StatsCardsProps {
  card: Card | [Card, Card];
}
const Container = styled.div<{ card: Card | [Card, Card] }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 120px;
  border: ${(props) =>
    !Array.isArray(props.card)
      ? `1px solid ${stylesVariables.StableGrayLight04}`
      : ""};
  border-radius: 4px;
`;

const Icon = styled.img<{
  iconSrc?: string;
}>`
  display: ${(props) => (!!props.iconSrc ? "unset" : "none")};
`;
const Stat = styled.p<{
  iconSrc?: boolean;
}>`
  font-family: "pulpDisplay", sans-serif;
  font-size: ${(props) => (props.iconSrc ? "1.125rem" : "1.5rem")};
  font-weight: 700;
`;
const Title = styled.p`
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: ${DSColors.SGDark01};
  text-align: center;
`;
const DoubleCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 1px solid ${stylesVariables.StableGrayLight04};
  border-radius: 4px;
`;
const DividingLine = styled.div`
  height: 62px;
  border: 1px solid ${DSColors.SGLight03};
`;
/**
 * value and title are mandatory
 */
export const StatsCards: React.FC<StatsCardsProps> = ({ card }) => {
  return !Array.isArray(card) ? (
    <Container card={card}>
      <Icon src={card.iconSrc} iconSrc={card.iconSrc} height={38} width={38} />
      <Stat iconSrc={!!card.iconSrc}>{card.value}</Stat>
      <Title>{card.title}</Title>
    </Container>
  ) : (
    <DoubleCardsContainer>
      <Container card={card}>
        <Icon
          src={card[0].iconSrc}
          iconSrc={card[0].iconSrc}
          height={38}
          width={38}
        />
        <Stat iconSrc={!!card[0].iconSrc}>{card[0].value}</Stat>
        <Title>{card[0].title}</Title>
      </Container>
      <DividingLine></DividingLine>
      <Container card={card}>
        <Icon
          src={card[1].iconSrc}
          iconSrc={card[1].iconSrc}
          height={38}
          width={38}
        />
        <Stat iconSrc={!!card[1].iconSrc}>{card[1].value}</Stat>
        <Title>{card[1].title}</Title>
      </Container>
    </DoubleCardsContainer>
  );
};
