import EligibilityForm from "./EligibilityForm";
import AppointmentForm from "./AppointmentForm";
import NavTitle from "../../utils/NavTitle";
import { useLocation } from "react-router";
import AppointmentSummary from "./AppointmentSummary";
import { Appointment } from "../MyAppointments/types";
import { Answers } from "../../config/questionary";

type RouteState = { answers: Answers; appointment: Appointment } | null;

export default function NewAppointment() {
  const { state } = useLocation();
  const routeState = state as RouteState;

  let step = "eligibilityForm";
  if (routeState?.answers) {
    step = "appointmentForm";
  }
  if (routeState?.appointment) {
    step = "appointmentSummary";
  }

  return (
    <>
      <NavTitle showBack>Prendre RDV</NavTitle>
      {step === "eligibilityForm" && <EligibilityForm />}
      {step === "appointmentForm" && <AppointmentForm />}
      {step === "appointmentSummary" && <AppointmentSummary />}
    </>
  );
}
