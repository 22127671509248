import { useEffect } from "react";
import { useMain } from "../MainProvider";
import { Navigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

export default function Logout() {
  const { user } = useMain();
  const { instance, inProgress } = useMsal();
  const loggingOut = inProgress === "logout";
  useEffect(() => {
    if (user) {
      instance.logoutRedirect();
    }
  }, []);
  if (!user && !loggingOut) {
    return <Navigate to="/" replace />;
  }
  if (loggingOut) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <LoadingComponent diameter={200} />
      </div>
    );
  }
  return null;
}
