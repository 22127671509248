import { NumericInput, NumericInputProps } from "./NumericInput";

const ConsommationEnergie = ({ ...props }: Omit<NumericInputProps, "unit">) => (
  <NumericInput {...props} unit="€" />
);
const Surface = ({ ...props }: Omit<NumericInputProps, "unit">) => (
  <NumericInput {...props} unit="m²" />
);

export const CUSTOM_INPUTS: Record<string, any> = {
  ConsommationEnergie,
  Surface,
};

export const CUSTOM_SUMMARIES: Record<
  string,
  React.FC<{ children: React.ReactNode }>
> = {
  ConsommationEnergie: ({ children }) => <>{`${children}€ / an`}</>,
  Surface: ({ children }) => <>{`${children}m²`}</>,
};

export default CUSTOM_INPUTS;
