import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { StatsCards } from "@web/shared/dist/components/DesignSystem/Cards/StatsCards/StatsCards";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import axios, { AxiosError } from "axios";
import moment from "moment";
import qs from "qs";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { UncontrolledAlert } from "reactstrap";
import { ENABLE_MANAGER_MODE, MOCK_HOME_STATS } from "../config/flags";
import { useMain } from "../MainProvider";
import { useMicrosoftGraph } from "../MicrosoftGraphProvider";
import { NavTitle } from "../utils/NavTitle";
import { withCache } from "../utils/restCache";

export default function Home() {
  const { user, store } = useMain();
  const { userProfile } = useMicrosoftGraph();
  const navigate = useNavigate();
  const [stats, setStats] = useState<Stats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const canSeeStoreSummary =
    ENABLE_MANAGER_MODE && !!user && user.isManager && !!store;

  useEffect(() => {
    (async () => {
      if (!userProfile || !store) return;
      setIsLoading(true);
      try {
        setStats(
          await getStats({
            userId: userProfile?.employeeId,
            storeId: store.id,
          })!
        );
      } catch (e) {
        const error = e as AxiosError<{ message: string }>;
        setErrorMessage(error.response?.data.message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [store, user, userProfile]);

  if (!user || !userProfile) return <Navigate replace to="/auth" />;
  if (!store) return <Navigate replace to="/store-choice" />;
  return (
    <>
      <NavTitle>Accueil</NavTitle>
      <div className="mb-3">
        <div className="">Bienvenue {user.name} 👋</div>
        <div className="">
          Magasin {store.name} - {store.id}
        </div>
      </div>
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "40vh" }}
        >
          <LoadingComponent diameter={200} />
        </div>
      )}
      {!!errorMessage && (
        <UncontrolledAlert color="warning" className="text-center">
          {"Erreur au chargement des statistiques. " + errorMessage}
        </UncontrolledAlert>
      )}
      {!!stats && (
        <>
          <div
            className="text-center mb-2"
            style={{ fontWeight: "bold", color: "#9f9f9f" }}
          >
            {capitalize(moment().format("MMMM YYYY"))} :
          </div>
          <div
            onClick={() => {
              navigate("/my-appointments");
            }}
          >
            <StatsCards
              card={[
                {
                  value: stats.nb_rendez_vous.toString(),
                  title: "RDV pris en magasin",
                },
                {
                  value: stats.nb_etudes_energetique.toString(),
                  title: "RDVs confirmés par Welcome Call",
                },
              ]}
            />
          </div>
          <div className="my-3" />
          <StatsCards
            card={[
              {
                value: stats.nb_ventes.toString(),
                title: "Bilans énergétiques réalisés",
              },
              {
                value: stats.nb_installations.toString(),
                title: "Installations effectuées",
              },
            ]}
          />
        </>
      )}

      <div className="mt-4 d-flex flex-column">
        <CTAButton
          name="Prendre RDV"
          category="tertiary"
          family="vitee"
          onClick={() => {
            navigate("/new-appointment");
          }}
        />
        <div className="my-2" />
        <CTAButton
          name="Mes RDVs"
          category="tertiary"
          family="vitee"
          onClick={() => {
            navigate("/my-appointments");
          }}
        />
        {canSeeStoreSummary && (
          <>
            <div className="my-2" />
            <CTAButton
              name="Mon magasin"
              category="tertiary"
              family="vitee"
              onClick={() => {
                navigate("/store-summary");
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export interface Stats {
  nb_rendez_vous: number;
  nb_etudes_energetique: number;
  nb_ventes: number;
  nb_installations: number;
}

async function getStats({
  userId,
  storeId,
}: {
  userId?: string | null;
  storeId?: string;
}) {
  return withCache("sellerStats", async () => {
    if (MOCK_HOME_STATS) {
      await new Promise((resolve) => {
        setTimeout(resolve, 300);
      });
      return {
        nb_rendez_vous: 0,
        nb_etudes_energetique: 0,
        nb_ventes: 0,
        nb_installations: 0,
      } as Stats;
    }

    const response = await axios.get<Stats>(
      `${process.env.REACT_APP_EERO_URL}/darty/user/leads_kpi?${qs.stringify({
        user_id: userId,
        store_id: storeId,
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
      })}`,
      {
        auth: {
          username: process.env.REACT_APP_EERO_LOGIN!,
          password: process.env.REACT_APP_EERO_PASSWORD!,
        },
      }
    );

    return response.data;
  });
}

function capitalize(str: string) {
  if (str.length === 0) return str;
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}
