import questionary from "./questionary.yml";

export interface Option {
  id: string;
  text: string;
  custom_input?: string;
  ineligible?: boolean;
  ineligible_reason?: string;
}

export interface Questionary {
  questions: {
    id: string;
    text: string;
    summary_text: string;
    options: Option[];
  }[];
}

export type Answers = Record<string, string | null>;

export default questionary as Questionary;
