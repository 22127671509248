import { useContext, useEffect, useRef } from "react";
import { DebugActionHookArg } from "./types";
import { DebugContext } from "./DebugContext";

export function useDebugAction({ label, onClick, skip }: DebugActionHookArg) {
  const onClickRef = useRef(onClick);
  onClickRef.current = onClick;
  const { registerDebugAction, unregisterDebugAction } =
    useContext(DebugContext);
  useEffect(() => {
    if (skip) return;
    const debugAction = registerDebugAction({ label, onClickRef });
    return () => {
      unregisterDebugAction(debugAction);
    };
  }, []);
}
