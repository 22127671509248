import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Store } from "./pages/StoreChoice";
import { User } from "./pages/Auth";
import { NavTitleValue } from "./utils/NavTitle";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

// remove old usage of localStorage
if (localStorage.getItem("authData")) {
  localStorage.removeItem("authData");
}

export const STORE_LOCAL_STORAGE_KEY = "storeData"; // change this key to invalidate localStorage for all users (they will have to sign in again)

interface MainState {
  store: Store | null;
  navTitle: NavTitleValue | null;
}

interface MainSetters {
  setStore: (store: Store | null) => void;
  setNavTitle: React.Dispatch<React.SetStateAction<NavTitleValue | null>>;
}

type MainContextValue = MainState & MainSetters & { user: User | null };

export const MainContext = createContext<MainContextValue>(
  null as unknown as MainContextValue
);

export function MainProvider({ children }: { children: React.ReactNode }) {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const account = isAuthenticated ? accounts[0] : null;
  let initialStoreValue = null;

  // unique synchronous check on mount
  const clearStoreFromLocalStorageCheck = useRef(false);
  if (!clearStoreFromLocalStorageCheck.current) {
    clearStoreFromLocalStorageCheck.current = true;
    if (!isAuthenticated) {
      localStorage.removeItem(STORE_LOCAL_STORAGE_KEY);
    }
  }

  if (isAuthenticated && localStorage.getItem(STORE_LOCAL_STORAGE_KEY)) {
    initialStoreValue = JSON.parse(
      localStorage.getItem(STORE_LOCAL_STORAGE_KEY)!
    );
  }
  const [store, setStore] = useState<Store | null>(initialStoreValue);
  useEffect(() => {
    localStorage.setItem(STORE_LOCAL_STORAGE_KEY, JSON.stringify(store));
  }, [store]);

  const [navTitle, setNavTitle] = useState<NavTitleValue | null>(null);

  const user = useMemo(
    () =>
      account && {
        id: account.username,
        name: account.name!,
        email: account.username,
        // MOCK
        isManager: false,
      },
    [account]
  );

  const ctx = useMemo(
    () => ({
      user,
      store,
      setStore,
      navTitle,
      setNavTitle,
    }),
    [user, store, setStore, navTitle, setNavTitle]
  );

  return <MainContext.Provider value={ctx}>{children}</MainContext.Provider>;
}

export function useMain() {
  const ctxValue = useContext(MainContext);
  if (!ctxValue) {
    throw new Error("MainContext should be present");
  }
  return ctxValue;
}
