// Libraries import
import React, { useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import loadingLottie from "../../../assets/Lotties/loading-animation-circle.json";
import styled from "styled-components";

const Container = styled.div`
  svg:nth-of-type(1) {
    display: block;
  }
  svg {
    display: none;
  }
`;

// Interface declaration
export interface LoadingComponentProps {
  diameter?: number;
}

export const LoadingComponent: React.FC<LoadingComponentProps> = ({
  diameter = 180,
}) => {
  const element = useRef(null);
  useEffect(() => {
    element.current &&
      lottie.loadAnimation({
        container: element.current,
        animationData: loadingLottie,
        renderer: "svg", // "canvas", "html"
        loop: true, // boolean
        autoplay: true, // boolean
      });
  }, [element]);

  return (
    <Container
      className="loading-component"
      style={{ width: diameter, height: diameter }}
      ref={element}
    />
  );
};
