// import React from "react";
import SVG from "react-inlinesvg";

// Styles import
import styled, { css } from "styled-components";

// imgs import
import checkbox from "../../../../assets/images/DesignSystemIcons/CheckboxIcons/checkbox.svg";
import checkboxFilled from "../../../../assets/images/DesignSystemIcons/CheckboxIcons/checkboxFilled.svg";
import { DSColors } from "../../../../styles/variables";

export interface CheckboxProps {
  color?: string;
  dimension?: string;
  onClick?: React.MouseEventHandler<SVGElement> | undefined;
  isChecked: boolean;
  required?: boolean;
}

const CheckboxContainer = styled.div`
  cursor: pointer;
`;

const LoadingImage = styled.img<{ dimension?: string }>`
  ${(props) =>
    props.dimension === "tall" &&
    css`
      height: 24px;
      width: 24px;
    `}
  ${(props) =>
    props.dimension === "small" &&
    css`
      height: 18px;
      width: 18px;
    `}
`;
const CheckboxSvg = styled(SVG)<{
  maincolor?: string;
  dimension?: string;
  active: number;
}>`
  &:hover {
    path {
      fill: ${(props) => props.maincolor};
    }
  }
  ${(props) =>
    props.active === 1 &&
    css`
      path {
        fill: ${props.maincolor};
      }
    `}

  ${(props) =>
    props.dimension === "tall" &&
    css`
      height: 24px;
      width: 24px;
    `}
  ${(props) =>
    props.dimension === "small" &&
    css`
      height: 18px;
      width: 18px;
    `}
`;

const RequiredStar = styled.span`
  color: ${DSColors.PowerRed};
`;

export const Checkbox: React.FC<CheckboxProps> = ({
  color,
  dimension,
  onClick,
  isChecked,
  required = false,
}) => {
  return (
    <CheckboxContainer>
      <CheckboxSvg
        onClick={onClick}
        active={isChecked ? 1 : 0}
        src={isChecked ? checkboxFilled : checkbox}
        maincolor={color}
        dimension={dimension}
        loader={<LoadingImage dimension={dimension} src={checkbox} />}
      />
      {required && (
        <RequiredStar>
          <sup> *</sup>
        </RequiredStar>
      )}
    </CheckboxContainer>
  );
};
