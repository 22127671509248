//Libraries import
import { useState } from "react";

//Services import
import { useAxiosPost } from "./useAxiosPost";

//Local interface

interface createAppointmentProps {
  dateTime: string;
  staffMemberIds: string;
  leadName: string;
  leadEmail: string;
  leadPhone: string;
  leadNote: string | null;
  partnerName?: string;
  partnerUserId?: string;
  address?: string;
  isDartyMax?: boolean;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
  departement?: string;
  partnerUserName?: string;
  partnerUserEmail?: string;
  partnerStoreId?: string;
  partnerStoreName?: string;
}

export const useCreateAppointment = () => {
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const createAppointment = async ({
    dateTime,
    staffMemberIds,
    leadName,
    leadEmail,
    leadPhone,
    leadNote,
    partnerName,
    partnerUserId,
    address,
    isDartyMax,
    zipCode,
    latitude,
    longitude,
    departement,
    partnerUserName,
    partnerUserEmail,
    partnerStoreId,
    partnerStoreName,
  }: createAppointmentProps): Promise<{
    appointmentID: string | null;
  }> => {
    setErrorMessage("");

    if (!dateTime || !staffMemberIds || !leadName || !leadEmail || !leadPhone) {
      setErrorMessage("Champs manquants");
      return { appointmentID: null };
    }

    const bodyRequest = {
      dateTime,
      staffMemberIds,
      leadName,
      leadEmail,
      leadPhone,
      leadNote,
      partnerName,
      partnerUserId,
      address,
      isDartyMax,
      zipCode,
      latitude,
      longitude,
      departement,
      partnerUserName,
      partnerUserEmail,
      partnerStoreId,
      partnerStoreName,
    };

    // #1 Hash login and password (token1 & token2) with the login and password from the ENV
    const token1 = process.env.REACT_APP_EERO_LOGIN || "";
    const token2 = process.env.REACT_APP_EERO_PASSWORD || "";

    const response = await axiosPostRequest<{ appointmentID: string }>(
      `${process.env.REACT_APP_EERO_URL}/www/bookingAppointment`,
      bodyRequest,
      {
        // #2 Send both token in auth
        auth: {
          username: token1,
          password: token2,
        },
      }
    );

    if (!response) {
      setErrorMessage("Impossible de créer un rendez vous.");
      return { appointmentID: null };
    }
    return { appointmentID: response?.data?.appointmentID || null };
  };

  return {
    createAppointment,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
