import { useContext } from "react";
import { NavItem } from "reactstrap";
import { DebugContext } from "./DebugContext";

export function DebugNavItems({ closeNav }: { closeNav: () => void }) {
  const debugContextValue = useContext(DebugContext);
  if (!debugContextValue) return null;
  return (
    <>
      {debugContextValue.debugActions.map(({ label, onClickRef }) => (
        <NavItem key={label!.toString()} className="text-black-50">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="nav-link"
            onClick={(e) => {
              e.preventDefault();
              onClickRef.current();
              closeNav();
            }}
          >
            {label}
          </a>
        </NavItem>
      ))}
    </>
  );
}
