// Libraries import
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios, { AxiosResponse } from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

interface MainState {
  userProfile: UserProfile | null;
  isLoadingFetchUserProfile: boolean;
  errorMessage: string;
}

export interface UserProfile {
  employeeId: string | null;
  // And all the other keys that are in the response from https://graph.microsoft.com/beta/me/
  // See https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-beta&tabs=http
}

interface FetchUserProfile {
  instance: IPublicClientApplication;
  account: AccountInfo | null;
}

type MicrosoftGraphValue = MainState;

export const MicrosoftGraph = createContext<MicrosoftGraphValue>(
  null as unknown as MicrosoftGraphValue
);

export function MicrosoftGraphProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const account = isAuthenticated ? accounts[0] : null;

  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isLoadingFetchUserProfile, setIsLoadingFetchUserProfile] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const ctx = useMemo(
    () => ({
      userProfile,
      isLoadingFetchUserProfile,
      errorMessage,
    }),
    [userProfile, isLoadingFetchUserProfile, errorMessage]
  );

  const getUserProfile = async ({ instance, account }: FetchUserProfile) => {
    if (account) {
      setIsLoadingFetchUserProfile(true);
      const accessTokenRequest = {
        scopes: ["User.Read"],
        account: account,
      };

      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        const userProfileResponse: AxiosResponse<UserProfile> = await axios.get(
          "https://graph.microsoft.com/beta/me/",
          {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          }
        );
        userProfileResponse.status === 200 &&
          setUserProfile(userProfileResponse.data);
      } catch (error) {
        setErrorMessage(
          "Impossible de récupérer les informations utilisateurs depuis https://graph.microsoft.com/beta/me/."
        );
        throw new Error(
          "Impossible to get user profile information from https://graph.microsoft.com/beta/me/."
        );
      } finally {
        setIsLoadingFetchUserProfile(false);
      }
    }
  };

  useEffect(() => {
    getUserProfile({ instance, account });
  }, [account, instance]);

  return (
    <MicrosoftGraph.Provider value={ctx}>{children}</MicrosoftGraph.Provider>
  );
}

export function useMicrosoftGraph() {
  const ctxValue = useContext(MicrosoftGraph);
  if (!ctxValue) {
    throw new Error("MicrosoftGraph should be present");
  }
  return ctxValue;
}
