// Libraries import
import { useState } from 'react'
import { useAxiosGet } from './useAxiosGet'

//Local interface
export interface IFormatStaffAvailabilityReturn {
  startTimeSlot: string
  endTimeSlot: string
  staffMembersAvailables: string[]
}
// Get swagger data from backend
export const useGetStaffAvailability = () => {
  const {
    axiosGetRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const getStaffAvailability = async ({
    selectedDate,
  }: {
    selectedDate: string
  }): Promise<IFormatStaffAvailabilityReturn[]> => {
    setErrorMessage('')

    // #1 Hash login and password (token1 & token2) with the login and password from the ENV
    const token1 = process.env.REACT_APP_EERO_LOGIN || ''
    const token2 = process.env.REACT_APP_EERO_PASSWORD || ''

    const response = await axiosGetRequest<IFormatStaffAvailabilityReturn[]>(
      `${process.env.REACT_APP_EERO_URL}/www/staffAvailability?dateTime=${selectedDate}`,
      {
        // #2 Send both token in auth
        auth: {
          username: token1,
          password: token2,
        },
      }
    )
    if (!response || response?.length === 0) {
      return []
    }
    return response && response
  }

  return {
    getStaffAvailability,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  }
}
