import STORES_CSV from "./stores.csv";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [headRow, ...storesRows] = STORES_CSV.split("\n");

if (storesRows[storesRows.length - 1] === "") {
  // handle eof newline
  storesRows.splice(storesRows.length - 1, 1);
}

export default storesRows.map((storeStr) => {
  const [storeBrand, id, locationLabel] = storeStr.split("\t");
  return {
    id,
    name: `${storeBrand} ${locationLabel}`,
  };
});
