import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import "moment/locale/fr";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./config/reportWebVitals";
import "./index.scss";
import { MainProvider } from "./MainProvider";
import { MicrosoftGraphProvider } from "./MicrosoftGraphProvider";
import { DebugProvider } from "./utils/debug";

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_DARTY_AZURE_AD_CLIENT_ID!,
    authority: `${process.env.REACT_APP_MICROSOFT_CLOUD_INSTANCE}${process.env.REACT_APP_DARTY_AZURE_AD_TENANT_ID}`,
  },
};

if (!configuration.auth.clientId) {
  console.error(`Missing env var REACT_APP_DARTY_AZURE_AD_CLIENT_ID`);
}

const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <SSOStartupCheck>
          <MicrosoftGraphProvider>
            <MainProvider>
              <DebugProvider>
                <App />
              </DebugProvider>
            </MainProvider>
          </MicrosoftGraphProvider>
        </SSOStartupCheck>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

function SSOStartupCheck({ children }: { children: React.ReactNode }) {
  const { inProgress } = useMsal();
  return (
    <>
      {inProgress !== "startup" && inProgress !== "handleRedirect" && children}
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
