// Libraries import
import React, { useEffect } from "react";

// Function that trigger when user click outside of the element (ref)
export const useTriggerFunctionWhenClickOutsideOfElement = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  action: () => void
): void => {
  useEffect(() => {
    // Close if clicked on outside of element
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        action();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [action, ref]);
};
