import { useEffect } from "react";
import { useMain } from "../MainProvider";

export interface NavTitleValue {
  title: React.ReactNode;
  showBack: boolean;
}

export function NavTitle({
  children,
  showBack,
}: {
  children: React.ReactNode;
  showBack?: boolean;
}) {
  const { setNavTitle } = useMain();
  useEffect(() => {
    setNavTitle({
      title: children,
      showBack: !!showBack,
    });
    return () => {
      setNavTitle(null);
    };
  }, []);
  return null;
}

export default NavTitle;
