//Librairies import
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

//Style import
import styles from "./PrendreRendezVous.module.scss";
import "./variables.scss";

// Components import
import { Layout } from "./Layout";
import { CalendrierJours } from "@web/shared/dist/components/DesignSystem/Calendrier/CalendrierJours/CalendrierJours";
import { ChoixUnique } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixUnique/ChoixUnique";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { TextArea } from "@web/shared/dist/components/DesignSystem/Inputs/TextArea/TextArea";
import { Checkbox } from "@web/shared/dist/components/DesignSystem/Checkboxes/Checkbox/Checkbox";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { SearchAddressInput } from "@web/shared/dist/components/DesignSystem/Inputs/SearchAddressInput/SearchAddressInput";

// images import
import clockIcon from "@web/shared/dist/assets/images/DesignSystemIcons/clock.svg";
import calendarIcon from "@web/shared/dist/assets/images/DesignSystemIcons/calendar.svg";
import personIcon from "@web/shared/dist/assets/images/DesignSystemIcons/person.svg";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import incentiveImg from "../../../../assets/images/incentive.png";

type TOption = {
  label: string;
  value: string;
};

interface IProps {
  selectedDate: Date | null;
  selectedHour: TOption | null;
  availableHours: TOption[];
  isChecked: boolean;
  userFirstName: string | null;
  userLastName: string | null;
  userEmail: string | null;
  userPhone: string | null;
  userNotes: string | null;
  setUserFirstName: Dispatch<SetStateAction<string | null>>;
  setUserLastName: Dispatch<SetStateAction<string | null>>;
  setUserEmail: Dispatch<SetStateAction<string | null>>;
  setUserPhone: Dispatch<SetStateAction<string | null>>;
  setUserNotes: Dispatch<SetStateAction<string | null>>;
  setSelectedHour: Dispatch<SetStateAction<TOption | null>>;
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
  getStaffAvailabilityFunction: ({
    selectedDate,
  }: {
    selectedDate: Date | [Date, Date] | null;
  }) => void;
  onFormSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  errorMessage: string | null;
  getStaffAvailabilityIsLoading: boolean;
  createBookingisLoading: boolean;
  pageBranding?: "default" | "darty" | "ekwateur";
  setAddress?: Dispatch<
    SetStateAction<{
      address?: string | undefined;
      latitude?: number | undefined;
      longitude?: number | undefined;
      zipCode?: string | undefined;
      departement?: string | undefined;
    } | null>
  >;
  setIsDartyMax: Dispatch<SetStateAction<boolean>>;
  isDartyMax: boolean;
  setMembershipCode: Dispatch<SetStateAction<string | null>>;
  membershipCode: string | null;
  fetchingNewAppointment: boolean;
}

export const PrendreRendezVous = ({
  selectedDate,
  selectedHour,
  availableHours,
  isChecked,
  userFirstName,
  userLastName,
  userEmail,
  userNotes,
  userPhone,
  setUserEmail,
  setUserFirstName,
  setUserNotes,
  setUserPhone,
  setUserLastName,
  setSelectedDate,
  setSelectedHour,
  onFormSubmit,
  setIsChecked,
  errorMessage,
  getStaffAvailabilityFunction,
  getStaffAvailabilityIsLoading,
  createBookingisLoading,
  pageBranding = "default",
  setAddress,
  isDartyMax,
  setIsDartyMax,
  membershipCode,
  setMembershipCode,
  fetchingNewAppointment,
}: IProps) => {
  const [showBookingSection, setShowBookingSection] = useState(false);
  const [showDartyMaxEmailWarning, setShowDartyMaxEmailWarning] =
    useState(false);
  useEffect(() => {
    if (isDartyMax) {
      setShowDartyMaxEmailWarning(true);
    }
  }, [isDartyMax]);
  const containerRef = useRef<HTMLElement>();
  const onDateChange = (value: Date | [Date, Date] | null) => {
    setSelectedDate(value as Date);
    setSelectedHour(null);
    getStaffAvailabilityFunction({ selectedDate: value });
    smoothScrollToElement({
      container: containerRef.current!,
      fromY: 140,
      elementSelector: "#hourSection",
    });
  };

  const onSelectLocation = ({
    description,
    zipCode,
    lat,
    lng,
  }: {
    lat: number;
    lng: number;
    zipCode: string;
    description: string;
  }) => {
    setAddress &&
      setAddress({
        address: description,
        zipCode,
        latitude: lat,
        longitude: lng,
        departement: zipCode.toString().slice(0, 2),
      });
  };

  const maxDate = (date: Date): Date => {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + 1);
    return dateCopy;
  };

  // We can not yet put the address as required as for some web browser version the fields is not displayed
  const isContinueButtonDisabled =
    !userEmail || !userFirstName || !userLastName || !userPhone || !isChecked;

  return (
    <div
      className={styles.prendreRendezVous}
      ref={(el) => {
        containerRef.current = el!;
      }}
    >
      <Layout>
        <div className={styles.prendreRendezVous__Container}>
          <section
            className={
              styles.prendreRendezVous__section_container +
              " " +
              styles.prendreRendezVous__vosInformations
            }
            id="contactSection"
          >
            <div className={styles.title}>
              <img src={personIcon} alt="" />
              <h2>Vos informations</h2>
            </div>

            <form action="" className={styles.form}>
              <div className={styles.main_information}>
                <div className={styles.firstname}>
                  <TextInput
                    inputLableText="Prénom : "
                    value={userFirstName || undefined}
                    onChange={(e) => setUserFirstName(e.target.value)}
                    inputDescriptionHidden
                    placeholder="Prénom*"
                    iconHidden
                    required
                    inputLableHidden={false}
                  />
                </div>
                <div className={styles.lastname}>
                  <TextInput
                    inputLableText="Nom : "
                    value={userLastName || undefined}
                    onChange={(e) => setUserLastName(e.target.value)}
                    inputDescriptionHidden
                    placeholder="Nom*"
                    iconHidden
                    required
                    inputLableHidden={false}
                  />
                </div>
                <div className={styles.email}>
                  <TextInput
                    inputLableText={`Email${
                      isDartyMax ? " du compte Darty Max" : ""
                    } : `}
                    placeholder="prénom.nom@gmail.com*"
                    value={userEmail || undefined}
                    onChange={(e) => setUserEmail(e.target.value)}
                    inputDescriptionHidden
                    iconHidden
                    required
                    inputLableHidden={false}
                  />
                </div>

                <Modal centered isOpen={showDartyMaxEmailWarning}>
                  <ModalBody className="m-2">
                    <p>
                      Pour bénéficier des avantages Darty Max, l'adresse mail
                      renseignée dans ce formulaire doit impérativement être la
                      même que celle de votre compte Darty Max.
                    </p>
                    <div className="mt-4 mb-2 d-flex justify-content-end">
                      <CTAButton
                        name="Compris"
                        category="primary"
                        onClick={() => {
                          setShowDartyMaxEmailWarning(false);
                        }}
                      />
                    </div>
                  </ModalBody>
                </Modal>

                <div className={styles.checkBox}>
                  <Checkbox
                    isChecked={isDartyMax}
                    onClick={() => setIsDartyMax((state) => !state)}
                  />
                  <p>Je suis client Darty Max</p>
                </div>

                <div className={styles.phone}>
                  <TextInput
                    inputLableText="Téléphone : "
                    placeholder="0123456789*"
                    value={userPhone || undefined}
                    onChange={(e) => setUserPhone(e.target.value)}
                    inputDescriptionHidden
                    iconHidden
                    required
                    inputLableHidden={false}
                  />
                </div>
                {setAddress ? (
                  <div className={styles.address}>
                    <Wrapper
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
                      libraries={["places"]}
                    >
                      <SearchAddressInput
                        onSelectLocation={onSelectLocation}
                        placeHolder="ex : 6 rue d'Italie, 49300 Cholet"
                        label="Adresse :"
                        labelHidden={false}
                      />
                    </Wrapper>
                  </div>
                ) : null}
              </div>

              <div className={styles.notes}>
                <TextArea
                  inputLableText="Notes : "
                  placeholder="Un commentaire ?"
                  value={userNotes || ""}
                  onChange={(e) => setUserNotes(e.target.value)}
                  inputLableHidden={false}
                />
                <div className={styles.membershipCode}>
                  <TextInput
                    inputLableText="Vous avez un code parrain ?"
                    placeholder="Code parrainage"
                    value={membershipCode || undefined}
                    onChange={(e) => setMembershipCode(e.target.value)}
                    iconHidden
                    inputLableHidden={false}
                  />
                </div>
                <div className={styles.checkBox}>
                  <Checkbox
                    isChecked={isChecked}
                    onClick={() => setIsChecked((state) => !state)}
                    required
                  />
                  <p>
                    Je souhaite être rappelé par TUCO afin d’être informé de
                    leurs produits et services. En remplissant le présent
                    formulaire, vous reconnaissez avoir pris connaissance et
                    compris notre{" "}
                    <a
                      href="https://www.tucoenergie.fr/mentions-legales/politique-de-confidentialite"
                      target="blank"
                      className="link-dark"
                    >
                      Politique de Confidentialité
                    </a>
                    , et avoir été informé de votre droit de vous inscrire sur
                    la liste d’opposition au démarchage téléphonique.
                  </p>
                </div>
              </div>
            </form>

            <div
              className={
                styles.prendreRendezVous__section_container +
                " " +
                styles.prendreRendezVous__button_container
              }
            >
              {errorMessage && (
                <div className={styles.prendreRendezVous__errorMessage}>
                  <p>{errorMessage}</p>
                </div>
              )}
              <div
                style={{
                  transition: "opacity 0.3s ease-out",
                  opacity: showBookingSection ? 0 : 1,
                  pointerEvents: showBookingSection ? "none" : "unset",
                }}
              >
                <CTAButton
                  name="Continuer"
                  onClick={(e) => {
                    setShowBookingSection(true);
                    smoothScrollToElement({
                      container: containerRef.current!,
                      fromY: 100,
                      elementSelector: "#dateSection",
                    });
                  }}
                  isDisabled={isContinueButtonDisabled}
                />
              </div>
            </div>
            <Row>
              <Col md={6} lg={4} className="my-4">
                <img src={incentiveImg} className="w-100" alt="" />
              </Col>
            </Row>
          </section>
          {showBookingSection && (
            <>
              <section
                id="dateSection"
                className={
                  styles.prendreRendezVous__section_container +
                  " " +
                  styles.prendreRendezVous__main
                }
              >
                <div className={styles.title}>
                  <img src={calendarIcon} alt="" />
                  <h2>Sélectionner une date</h2>
                </div>
                <div className={styles.prendreRendezVous__Calendar}>
                  <div
                    className={
                      styles.calendar_container +
                      " " +
                      styles.prendreRendezVous__calendarDesktop
                    }
                  >
                    <CalendrierJours
                      value={selectedDate}
                      onChange={onDateChange}
                      disabledSundays
                      disabledSaturdays
                      minDate={new Date()}
                      maxDate={maxDate(new Date())}
                      calendarSize={"big"}
                    />
                  </div>
                  <div
                    className={
                      styles.calendar_container +
                      " " +
                      styles.prendreRendezVous__calendarMobile
                    }
                  >
                    <CalendrierJours
                      value={selectedDate}
                      onChange={onDateChange}
                      disabledSundays
                      disabledSaturdays
                      minDate={new Date()}
                      maxDate={maxDate(new Date())}
                      calendarSize={"small"}
                    />
                  </div>
                </div>
              </section>
              {selectedDate && (
                <section
                  className={styles.prendreRendezVous__section_container}
                  id="hourSection"
                >
                  {getStaffAvailabilityIsLoading && !selectedHour ? (
                    <div className={styles.prendreRendezVous__LoadingContainer}>
                      <LoadingComponent />
                    </div>
                  ) : (
                    <>
                      <div className={styles.title}>
                        <img src={clockIcon} alt="" />
                        <h2>Sélectionner une heure</h2>
                      </div>

                      <p>
                        Pour le{" "}
                        {selectedDate.toLocaleDateString("fr-FR", {
                          day: "2-digit",
                          month: "long",
                        })}
                        &nbsp;:
                      </p>

                      <div className={styles.available_hours}>
                        <ChoixUnique
                          gap={true}
                          optionSelected={
                            !selectedHour ? undefined : selectedHour
                          }
                          optionsList={availableHours}
                          size={window.innerWidth <= 390 ? "small" : "normal"}
                          onSelect={(option) => {
                            setSelectedHour(option);
                            smoothScrollToElement({
                              container: containerRef.current!,
                              fromY: window.innerHeight - 90,
                              elementSelector: "#confirmSection",
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                </section>
              )}
            </>
          )}
          {!!selectedDate && !!selectedHour && (
            <div
              className="mt-4 d-flex justify-content-center"
              id="confirmSection"
            >
              <CTAButton
                name="Confirmer mon rendez-vous"
                onClick={(e) => onFormSubmit(e)}
                isDisabled={
                  isContinueButtonDisabled || !selectedDate || !selectedHour
                }
                isLoading={
                  createBookingisLoading ||
                  fetchingNewAppointment ||
                  getStaffAvailabilityIsLoading
                }
              />
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};

export async function smoothScrollToElement({
  fromEl,
  fromY: _fromY,
  elementSelector,
  container,
}: {
  fromEl?: HTMLElement;
  fromY?: number;
  elementSelector: string;
  container: HTMLElement;
}) {
  await new Promise((resolve) => {
    setTimeout(resolve, 50);
  });
  const toEl = window.document.querySelector(elementSelector)!;
  const fromY = fromEl ? fromEl.getBoundingClientRect().y : _fromY!;
  const scrollDiff = toEl.getBoundingClientRect().y - fromY;

  const additionnalWindowHeightNeeded =
    window.innerHeight -
    document.body.getBoundingClientRect().bottom +
    scrollDiff;

  if (scrollDiff < 0) return;

  if (additionnalWindowHeightNeeded > 0) {
    container.style.minHeight = `${
      container.getBoundingClientRect().height + additionnalWindowHeightNeeded
    }px`;
  }

  window.scrollBy({
    top: scrollDiff,
    left: 0,
    behavior: "smooth",
  });
}
