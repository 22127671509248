import { StatsCards } from "@web/shared/dist/components/DesignSystem/Cards/StatsCards/StatsCards";
import { useMain } from "../MainProvider";
import { Navigate } from "react-router";
import { useEffect, useState } from "react";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { Stats } from "./Home";
import { NavTitle } from "../utils/NavTitle";
import { useMicrosoftGraph } from "../MicrosoftGraphProvider";

export default function StoreSummary() {
  const { user, store } = useMain();
  const { userProfile } = useMicrosoftGraph();
  const [stats, setStats] = useState<Stats | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!user || !store || !user.isManager) return;
    (async () => {
      setLoading(true);
      try {
        setStats(
          await getStoreStats({
            userId: userProfile?.employeeId,
            storeId: store.id,
          })
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (!store || !user || !user.isManager) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      <NavTitle showBack>
        Magasin {store.name} - {store.id}
      </NavTitle>
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "40vh" }}
        >
          <LoadingComponent diameter={200} />
        </div>
      )}
      {!!stats && (
        <>
          <StatsCards
            card={[
              {
                value: stats.nb_rendez_vous.toString(),
                title: "RDVs pris",
              },
              {
                value: stats.nb_etudes_energetique.toString(),
                title: "RDVs complétés",
              },
            ]}
          />
          <div className="my-3" />
          <StatsCards
            card={[
              {
                value: stats.nb_ventes.toString(),
                title: "Ventes",
              },
              {
                value: stats.nb_installations.toString(),
                title: "Installations",
              },
            ]}
          />
        </>
      )}
    </>
  );
}

async function getStoreStats({
  userId,
  storeId,
}: {
  userId?: string | null;
  storeId?: string;
}): Promise<Stats> {
  await new Promise((resolve) => {
    setTimeout(resolve, 300);
  });
  return {
    nb_rendez_vous: 0,
    nb_etudes_energetique: 0,
    nb_ventes: 0,
    nb_installations: 0,
  };
}
