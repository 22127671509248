// Libraries import
import { useState } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

//Interfaces declaration
interface IFunctionReturn {
  axiosPostRequest: <T>(
    URL: string,
    body: unknown,
    config?: AxiosRequestConfig | undefined
  ) => Promise<AxiosResponse<T> | void>
  isLoading: boolean
  errorMessage: string
}

export const useAxiosPost = (): IFunctionReturn => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const axiosPostRequest = async <T>(
    URL: string,
    body: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<T> | void> => {
    setIsLoading(true)
    setErrorMessage('')

    try {
      const response = await axios.post<T>(URL, body, config)
      if (response.status.toString()[0] === '2') {
        setIsLoading(false)
        return response
      }
      setErrorMessage('Une erreur est survenue, merci de réessayer.')
      setIsLoading(false)
      return
    } catch (error: any) {
      setIsLoading(false)
      return
    }
  }

  return { axiosPostRequest, isLoading, errorMessage }
}
