import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { DatePicker } from "@web/shared/dist/components/DesignSystem/Picker/DatePicker/DatePicker";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import axios, { AxiosError } from "axios";
import cn from "classnames";
import moment from "moment";
import qs from "qs";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { UncontrolledAlert } from "reactstrap";
import { MOCK_MY_APPOINTMENTS } from "../../config/flags";
import { useMain } from "../../MainProvider";
import { useMicrosoftGraph } from "../../MicrosoftGraphProvider";
import { NavTitle } from "../../utils/NavTitle";
import { withCache } from "../../utils/restCache";
import _MOCKED_APPOINTMENTS from "./mockedAppointments.json";
import { Appointment, LeadStatusValue } from "./types";
export const MOCKED_APPOINTMENTS: Appointment[] =
  _MOCKED_APPOINTMENTS as Appointment[];

export default function MyAppointments() {
  const { user, store } = useMain();
  const { userProfile } = useMicrosoftGraph();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [appointments, setAppointments] = useState<Appointment[] | null>(null);
  const { search } = useLocation();
  const { focus_appointment: focusedAppointmentIdFromUrl } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const [focusedAppointmentId, setFocusedAppointmentId] = useState<string>();
  const [dateRange, setDateRange] = useState<[Date, Date]>(
    getDefaultDateRange()
  );
  useEffect(() => {
    if (!userProfile || !store) return;
    if (focusedAppointmentIdFromUrl) {
      setFocusedAppointmentId(focusedAppointmentIdFromUrl as string);
      navigate({ search: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!userProfile || !store) return;
    (async () => {
      setIsLoading(true);
      try {
        setAppointments(
          await getMyAppointments({
            userId: userProfile?.employeeId,
            storeId: store.id,
            dateRange,
          })
        );
      } catch (e) {
        const error = e as AxiosError<{ message: string }>;
        setErrorMessage(error.response?.data.message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dateRange, store, userProfile]);
  if (!user || !store) return <Navigate replace to="/" />;
  return (
    <>
      <NavTitle showBack>Mes RDVs</NavTitle>
      <DatePicker
        selectRange
        dateSelected={dateRange}
        onSelect={(option) => setDateRange(option as unknown as [Date, Date])}
      />
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "40vh" }}
        >
          <LoadingComponent diameter={200} />
        </div>
      )}
      {!!errorMessage && (
        <UncontrolledAlert color="warning" className="mt-4 text-center">
          {`Erreur au chargement des RDVs : ${errorMessage}`}
        </UncontrolledAlert>
      )}
      {appointments &&
        (appointments.length ? (
          appointments.map(({ lead_id, created_at, lead_status, name }) => (
            <div
              key={lead_id}
              className={cn("appointment my-4 py-3 px-3 rounded-3", {
                focused: lead_id === focusedAppointmentId,
              })}
            >
              <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                Pris le {moment(created_at).format("D MMMM YYYY")}
              </div>
              <div className="text-muted" style={{ fontSize: "0.9em" }}>
                <div className="mt-2">
                  {/* <div>
                    <strong>Réf :</strong> {lead_id}
                  </div> */}
                  <div>
                    <strong>Client :</strong> {name}
                  </div>
                </div>
                <div className="mt-2">
                  {Object.entries(lead_status).map(
                    ([step, { date, status }]) => (
                      <div key={step}>
                        <strong>{STEPS[step]} :</strong>{" "}
                        {STATUS[status] || status || "N/A"}
                        {!!date && ` (${moment(date).format("DD/MM/YYYY")})`}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className="text-muted text-center d-flex flex-column align-items-center justify-content-center"
            style={{ height: "40vh", fontSize: "1.1rem" }}
          >
            <div>(aucun RDV trouvé)</div>
            <div className="mt-4" />
            <CTAButton
              name="Retour à l'accueil"
              category="secondary"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        ))}
    </>
  );
}

export async function getMyAppointments({
  userId,
  storeId,
  dateRange,
}: {
  userId?: string | null;
  storeId?: string;
  dateRange: [Date, Date];
}) {
  const [start_date, end_date] = [
    moment(dateRange[0]).format("YYYY-MM-DD"),
    moment(dateRange[1]).format("YYYY-MM-DD"),
  ];

  return withCache(`myAppointments(${start_date}..${end_date})`, async () => {
    if (MOCK_MY_APPOINTMENTS) {
      await new Promise((resolve) => {
        setTimeout(resolve, 300);
      });
      return MOCKED_APPOINTMENTS;
    }
    const response = await axios.get<Appointment[]>(
      `${process.env.REACT_APP_EERO_URL}/darty/user/leads?${qs.stringify({
        user_id: userId,
        store_id: storeId,
        start_date,
        end_date,
      })}`,
      {
        // #2 Send both token in auth
        auth: {
          username: process.env.REACT_APP_EERO_LOGIN!,
          password: process.env.REACT_APP_EERO_PASSWORD!,
        },
      }
    );

    return response.data;
  });
}

export const STEPS: Record<string, string> = {
  rendez_vous: "Rendez-vous",
  etude_energetique: "Étude énergétique",
  vente: "Vente",
  installation: "Installation",
};

export const STATUS: Record<LeadStatusValue, string> = {
  done: "Fait",
  planned: "Planifié",
  not_started: "Pas fait",
  canceled: "Annulé",
};

export function getDefaultDateRange(): [Date, Date] {
  return [moment().startOf("month").toDate(), moment().endOf("month").toDate()];
}

export function stringifyDateRange(dateRange: [Date, Date]) {
  return `${moment(dateRange[0]).format("YYYY-MM-DD")}..${moment(
    dateRange[1]
  ).format("YYYY-MM-DD")}`;
}

Object.assign(window, { getDefaultDateRange, moment });
