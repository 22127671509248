// imgs import
import eyePwdIcon from "../../../../assets/images/DesignSystemIcons/eye-pwd-icon.svg";
import eyePwdIconRed from "../../../../assets/images/DesignSystemIcons/eye-pwd-icon-red.svg";
import eyePwdIconBold from "../../../../assets/images/DesignSystemIcons/eye-pwd-icon-bold.svg";
import squaredMetersIcon from "../../../../assets/images/DesignSystemIcons/squred-meter.svg";
import metersIcon from "../../../../assets/images/DesignSystemIcons/meter.svg";
import eurosIcon from "../../../../assets/images/DesignSystemIcons/euro.svg";
// import React from "react";
import React, { useState } from "react";
// Styles import
import styled from "styled-components";
import { DSColors } from "../../../../styles/variables";

export interface TextInputProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  autoComplete?: string;
  iconHidden?: boolean;
  inputLableHidden?: boolean;
  inputDescriptionHidden?: boolean;
  inputLableText?: string;
  inputDescriptionText?: string;
  customInputWrapperStyle?: React.CSSProperties;
  error?: boolean;
  isDisabled?: boolean;
  type?: string;
  iconType?: "squaredMeters" | "meters" | "euros" | "degrees" | string;
  fontSize?: number;
  required?: boolean;
  name?: string;
}

export interface TextInputWrapperProps {
  value?: string;
  error?: boolean;
  isInputOnFocus?: boolean;
  isDisabled?: boolean;
}

export interface TextLableProps {
  error?: boolean;
  isInputOnFocus?: boolean;
  value?: string;
  isDisabled?: boolean;
}

export interface TextDescriptionProps {
  error?: boolean;
  isDisabled?: boolean;
}

export interface ButtonProps {
  isDisabled?: boolean;
  iconType?: string;
}

const InputWrapper = styled.div<TextInputWrapperProps>`
  background-color: ${(props) =>
    props.isDisabled ? DSColors.SGLight05 : DSColors.white};
  border: 1px solid
    ${(props) =>
      props.isDisabled
        ? DSColors.SGDark01
        : props.error
        ? DSColors.PowerRed
        : props.isInputOnFocus
        ? DSColors.Mint
        : DSColors.SGLight03};
  border-radius: 4px;
  display: flex;
  align-items: center;
  max-width: 30rem;
  min-height: 46px;

  &:hover {
    border: 1px solid
      ${(props) =>
        props.isDisabled
          ? DSColors.SGDark01
          : props.error
          ? DSColors.PowerRed
          : DSColors.Mint};
  }
`;
const Input = styled.input<TextInputProps>`
  background-color: ${(props) =>
    props.isDisabled ? DSColors.SGLight05 : DSColors.white};
  margin-left: 12px;
  font-family: "DM sans", sans-serif;
  width: 100%;
  height: 100%;
  font-weight: 400;
  box-sizing: border-box;
  border: none;
  font-size: ${(props) => `${props.fontSize}px`};

  ::placeholder {
    font-size: ${(props) => `${props.fontSize}px`};
  }

  color: ${(props) => (props.error ? DSColors.PowerRed : DSColors.OffBlack)};

  &:focus {
    ::placeholder {
      color: transparent;
    }
  }

  text-align: left;

  &::placeholder {
    color: ${(props) => (props.error ? DSColors.PowerRed : DSColors.SGLight02)};
    font-family: "DM sans", sans-serif;
    font-size: 16px;
  }
`;

const Button = styled.button<ButtonProps>`
  margin-top: 2px;
  margin-right: 15px;
  background: none;
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
`;

const InputLable = styled.p<TextLableProps>`
  text-align: start;
  font-family: "DM sans", sans-serif;
  font-size: 14px;
  color: ${(props) =>
    props.error
      ? DSColors.PowerRed
      : !props.isInputOnFocus
      ? DSColors.StableGrey
      : !!props.value
      ? DSColors.Mint
      : props.isInputOnFocus
      ? DSColors.Mint
      : DSColors.StableGrey};
  margin-bottom: 4px;
`;

const InputDescription = styled.p<TextDescriptionProps>`
  text-align: start;
  font-family: "DM sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.error ? DSColors.PowerRed : DSColors.SGLight02)};
  margin-top: 4px;
`;

const RequiredStar = styled.span`
  color: ${DSColors.PowerRed};
`;

/**
 - No mandatory props
 - Default props are listed below
 **/
export const TextInput: React.FC<TextInputProps> = ({
  value = "",
  onChange,
  placeholder = "Input placeholder",
  autoComplete = "off",
  iconHidden = true,
  iconType = "",
  inputLableText = "Input lable :",
  inputDescriptionText = "Description",
  inputLableHidden = true,
  inputDescriptionHidden = true,
  error = false,
  isDisabled = false,
  type = "text",
  fontSize = 16,
  required = false,
  name,
  customInputWrapperStyle = {},
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isInputOnFocus, setIsInputOnFocus] = useState<boolean>(false);

  return (
    <div>
      <InputLable
        isDisabled={isDisabled}
        isInputOnFocus={isInputOnFocus}
        hidden={inputLableHidden}
        error={error}
        value={value}
      >
        {inputLableText}
        <RequiredStar>{required ? " *" : ""}</RequiredStar>
      </InputLable>
      <InputWrapper
        value={value}
        isDisabled={isDisabled}
        error={error}
        isInputOnFocus={isInputOnFocus}
        style={customInputWrapperStyle}
      >
        <Input
          required={required}
          fontSize={fontSize}
          isDisabled={isDisabled}
          disabled={isDisabled}
          error={error}
          placeholder={placeholder}
          name={name}
          type={
            type === "password"
              ? isPasswordVisible
                ? "text"
                : "password"
              : type
          }
          value={value}
          autoComplete={autoComplete}
          onChange={onChange}
          onFocus={() => setIsInputOnFocus(true)}
          onBlur={() => setIsInputOnFocus(false)}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <Button
          iconType={iconType}
          isDisabled={isDisabled}
          disabled={isDisabled}
          hidden={iconHidden}
          onClick={(e) => {
            e.preventDefault();
            if (type === "password") {
              setIsPasswordVisible((prev) => !prev);
            }
          }}
          type="button"
        >
          {iconType === "squaredMeters" ? (
            <img src={squaredMetersIcon} alt={"icon"} />
          ) : iconType === "degrees" ? (
            <p style={{ fontWeight: "bold" }}>°</p>
          ) : iconType === "meters" ? (
            <img src={metersIcon} alt={"icon"} />
          ) : iconType === "euros" ? (
            <img src={eurosIcon} alt={"icon"} />
          ) : iconType ? (
            <p style={{ fontWeight: "bold" }}>{iconType}</p>
          ) : isDisabled ? (
            <img src={eyePwdIcon} alt={"grey opened eye"} />
          ) : !!value && !error ? (
            <img src={eyePwdIconBold} alt={"bold opened eye"} />
          ) : isPasswordVisible && !error ? (
            <img src={eyePwdIcon} alt={"grey closed eye"} />
          ) : !isPasswordVisible && !error ? (
            <img src={eyePwdIcon} alt={"grey opened eye"} />
          ) : isPasswordVisible && error ? (
            <img src={eyePwdIconRed} alt={"red closed eye"} />
          ) : !isPasswordVisible && error ? (
            <img src={eyePwdIconRed} alt={"red opened eye"} />
          ) : (
            <img src={eyePwdIcon} alt={"grey closed eye"} />
          )}
        </Button>
      </InputWrapper>
      <InputDescription
        isDisabled={isDisabled}
        hidden={inputDescriptionHidden}
        error={error}
      >
        {inputDescriptionText}
      </InputDescription>
    </div>
  );
};
