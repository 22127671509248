import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Collapse, Nav, NavItem, Navbar, NavbarBrand } from "reactstrap";
import { useMain } from "./MainProvider";
import dartyLogo from "./assets/images/darty-logo.png";
import WheelIcon from "./assets/images/wheel.svg?component";
import BackIcon from "./assets/images/back.svg?component";
import { ENABLE_AUTH_DEBUG } from "./config/flags";
import { DebugNavItems } from "./utils/debug";

function Layout({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { user, store, navTitle } = useMain();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
    window.scrollTo({
      top: 0,
    });
  }, [pathname, JSON.stringify(state)]);
  return (
    <>
      <div className="pt-5" />
      <Navbar
        style={{
          backgroundColor: "#f8fbfd",
          borderBottom: "solid 2px #deebf3",
        }}
        fixed="top"
      >
        <NavbarBrand
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          {!!navTitle && navTitle.showBack && (
            <BackIcon
              style={{
                height: 36,
                width: 20,
                position: "absolute",
                transform: "scale(0.7, 1)",
                marginLeft: -11,
                opacity: 0.5,
                pointerEvents: "none",
              }}
            />
          )}
          <img
            alt="logo"
            src={dartyLogo}
            style={{ height: "1.4em" }}
            className="me-3 ms-2"
          />
          {navTitle ? navTitle.title : "Darty ⨉ Tuco"}
        </NavbarBrand>
        {(!!user || ENABLE_AUTH_DEBUG) && (
          <WheelIcon
            style={{ height: 36, width: 36, color: "#b1c0c9" }}
            className="mt-1 me-2"
            onClick={toggle}
          />
        )}
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink className="nav-link" to="/support">
                Support
              </NavLink>
            </NavItem>
            {!!store && (
              <NavItem>
                <NavLink className="nav-link" to="/store-choice">
                  Changer de magasin
                </NavLink>
              </NavItem>
            )}
            {!!user && (
              <NavItem>
                <NavLink className="nav-link" to="/logout">
                  Déconnexion
                </NavLink>
              </NavItem>
            )}
            <DebugNavItems
              closeNav={() => {
                setIsOpen(false);
              }}
            />
          </Nav>
        </Collapse>
      </Navbar>
      {children}
    </>
  );
}

export default Layout;
