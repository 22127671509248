// Libraries import
import { useState, useRef } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

// Styles import
import { DSColors } from "../../../../styles/variables";

// Service import
import { useTriggerFunctionWhenClickOutsideOfElement } from "../../../../services/useTriggerFunctionWhenClickOutsideOfElement";

//Images import
import calendarIcon from "../../../../assets/images/DesignSystemIcons/calendar.svg";
import periode from "../../../../assets/images/DesignSystemIcons/periode.svg";

import { CalendrierJours } from "../../Calendrier/CalendrierJours/CalendrierJours";

/** options, optionsSelected and onSelect are mandatory **/
export interface SelectDropdownProps {
  /** list of options selected by the user shown in the tag shared component - it is possible to deselect the single option shown **/
  dateSelected: Date | [Date, Date] | null;
  /** single option selected by the user shown in the input field **/
  id?: string;
  isRequired?: boolean;
  /** function to execute when the user selects a dropdown option (feeds the list of selected options) **/
  onSelect: (date: Date) => void;
  /** true for use outside tables - false for use in tables **/
  background?: boolean;
  /** true to show values instead of lables **/
  showValue?: boolean;
  placeholder?: string;
  fontSize?: number;
  selectRange?: boolean;
}

const WrapperButton = styled.div`
  position: relative;
`;

const InputContainer = styled.div<{
  isSelectOpen: boolean;
  isValueSelected: boolean;
  isSearchable: boolean;
  isBorder: boolean;
  isBackground: boolean;
  selectRange: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-width: 129px;
  font-family: "DM Sans", serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 0;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isBackground ? `${DSColors.white}` : `none`};
  cursor: pointer;
  z-index: 1;
  height: 46px;
  padding: 12px;
  box-sizing: border-box;
  color: ${(props) =>
    props.isValueSelected ? `${DSColors.OffBlack}` : `${DSColors.StableGrey}`};
  border: ${(props) =>
    props.isSelectOpen && props.isBorder
      ? `1px solid ${DSColors.Mint}`
      : props.isBorder === false
      ? "1px solid rgba(255, 0, 0, 0)"
      : `1px solid ${DSColors.SGLight02}`};
  overflow: hidden;
  position: relative;

  &:hover {
    border: 1px solid ${DSColors.Mint};
    color: ${DSColors.Mint};

    path {
      fill: ${DSColors.Mint};
    }
  }

  > input {
    z-index: 1;
    all: unset;
    width: 100%;
    text-align: left;
    caret-color: ${(props) => (props.isSearchable ? "auto" : "transparent")};
    cursor: ${(props) => (props.isSearchable ? "auto" : "pointer")};
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 3rem;

    &::placeholder {
      font-style: italic;
    }
  }
`;

const DropdownContainer = styled.ul<{ isSelectOpen: boolean }>`
  box-shadow: 0 20px 80px rgba(14, 31, 63, 0.07);
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  display: ${(props) => (props.isSelectOpen ? `block` : `none`)};
  z-index: 2;
  width: fit-content;
  overflow-y: scroll;
  position: absolute;
  background-color: ${DSColors.white};
`;

const Title = styled.p<{ fontSize?: number; isInputSelected: boolean }>`
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${(props) =>
    props.isInputSelected ? DSColors.OffBlack : DSColors.SGLight02};
  font-family: "DM Sans";
`;

const PeriodeContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  gap: 2px;

  > p:last-child {
    margin-left: 0;
  }
`;
const SVGPeriode = styled.img<{}>`
  display: inline;
`;

const CalendarSvg = styled(SVG)<{ opened?: number; active?: number }>`
  width: 16px;
  height: auto;
  display: block;
  filter: ${(props) =>
    props.opened === 1
      ? "invert(31%) sepia(72%) saturate(1669%) hue-rotate(189deg) brightness(97%) contrast(110%)"
      : "invert(54%) sepia(8%) saturate(276%) hue-rotate(186deg) brightness(90%) contrast(95%)"};

  path {
    fill: ${(props) =>
      props.opened === 1
        ? `${DSColors.OffBlack}`
        : props.active === 1
        ? `${DSColors.OffBlack}`
        : `${DSColors.SGLight02}`};
  }
`;

export const DatePicker = ({
  dateSelected,
  onSelect,
  background = true,
  placeholder = "De",
  fontSize = 16,
  selectRange = false,
}: SelectDropdownProps) => {
  // Hooks call
  const selectRef = useRef(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  // Events handlers
  const handleToggleSelect = () => {
    setIsSelectOpen((state) => !state);
  };
  const handleCloseSelect = () => {
    setIsSelectOpen(() => false);
  };

  const isInputSelected = () => {
    if (Array.isArray(dateSelected)) {
      return Boolean(dateSelected[0] && dateSelected[1]);
    } else {
      return Boolean(dateSelected);
    }
  };

  // Close the dropdown when click outside of the dropdown
  useTriggerFunctionWhenClickOutsideOfElement(selectRef, handleCloseSelect);
  return (
    <WrapperButton ref={selectRef}>
      <InputContainer
        isSearchable={false}
        isValueSelected={isInputSelected()}
        onClick={handleToggleSelect}
        isSelectOpen={isSelectOpen}
        isBorder={background ? true : background === false && isSelectOpen}
        isBackground={!!background}
        selectRange={selectRange}
      >
        <CalendarSvg
          active={dateSelected ? 1 : 0}
          opened={isSelectOpen ? 1 : 0}
          src={calendarIcon}
        />
        {/*if there is a value either in the options selected list or in the single option selected, the placeholder/title is not shown*/}

        {selectRange ? (
          <PeriodeContainer>
            <Title fontSize={fontSize} isInputSelected={isInputSelected()}>
              {dateSelected && Array.isArray(dateSelected) && dateSelected[0]
                ? dateSelected[0].toLocaleDateString("fr-FR")
                : "JJ/MM/AAAA"}
            </Title>

            <SVGPeriode src={periode} alt="" />

            <Title fontSize={fontSize} isInputSelected={isInputSelected()}>
              {dateSelected && Array.isArray(dateSelected) && dateSelected[1]
                ? dateSelected[1].toLocaleDateString("fr-FR")
                : "JJ/MM/AAAA"}
            </Title>
          </PeriodeContainer>
        ) : (
          (
            <Title fontSize={fontSize} isInputSelected={isInputSelected()}>
              {dateSelected && !Array.isArray(dateSelected)
                ? dateSelected.toLocaleDateString("fr-FR")
                : "JJ/MM/AAAA"}
            </Title>
          ) || placeholder
        )}
      </InputContainer>
      <DropdownContainer isSelectOpen={isSelectOpen}>
        <CalendrierJours
          value={dateSelected}
          onChange={onSelect}
          selectRange={selectRange}
        />
      </DropdownContainer>
    </WrapperButton>
  );
};
