// Libraries import
import styled from "styled-components";

// Styles import
import { DSColors, stylesVariables } from "../../../../styles/variables";

import Calendar from "react-calendar";
import { View } from "react-calendar/dist/cjs/shared/types";

// Images imports
import chevronDown from "../../../../assets/images/chevron-down.svg";

export interface ICalendrierJoursProps {
  /** Value of type Date | [Date, Date] that can be converted in string with value.toLocaleDateString("fr-FR") or any other format functions */
  value: Date | [Date, Date] | null;
  /** Function to execute when the user change the selected Date */
  onChange: (
    value: Date | [Date, Date],
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  /** Tells if the user should chose one single Date or 2 Dates (range Dates) */
  selectRange?: boolean;
  /** Tells if the calendar view should be 'century' | 'decade' | 'year' | 'month' (NOT FINISHED YET) */
  view?: View;
  /** Tells if the calendar should block any choices before this date */
  minDate?: Date | undefined;
  /** Tells if the calendar should block any choices after this date */
  maxDate?: Date | undefined;
  /** Gives a list of Dates that should be disabled */
  disabledDates?: Date[];
  /** Tells if all saturdays should be blocked */
  disabledSaturdays?: boolean;
  /** Tells if all sundays should be blocked */
  disabledSundays?: boolean;
  /** Tells if all sundays should be blocked */
  calendarSize?: "small" | "big";
}

const Wrapper = styled.div<{ calendarSize: "small" | "big" }>`
  .react-calendar {
    --tile-border-radius: 50px;
    background-color: ${DSColors.white};
    -webkit-box-shadow: 0px 0px 14px rgba(25, 27, 30, 0.12);
    box-shadow: 0px 0px 14px rgba(25, 27, 30, 0.12);
    border: none;
    border-radius: 4px;
    width: 100%;
    max-width: ${(props) =>
      props.calendarSize === "big" ? "30rem" : "22.25rem"};
    padding: 2rem;

    font-family: "PulpDisplay";

    &__navigation {
      display: flex;
      justify-content: center;
      flex-grow: 0;
      gap: 10px;

      &__label {
        background-color: ${DSColors.white} !important;
        color: ${stylesVariables.OffBlack};
        &:hover {
          background-color: ${DSColors.white} !important;
        }

        &__labelText {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 1.125rem;
        }
      }

      &__arrow {
        background-color: ${DSColors.white} !important;
        &:hover {
          background-color: ${DSColors.white} !important;
          > img {
            filter: invert(12%) sepia(96%) saturate(7215%) hue-rotate(200deg)
              brightness(96%) contrast(94%);
          }
        }
        &:disabled {
          background-color: ${DSColors.white};
          > img {
            filter: invert(74%) sepia(5%) saturate(226%) hue-rotate(185deg)
              brightness(92%) contrast(88%);
          }
        }
      }
    }

    &__viewContainer {
      height: 100%;
    }

    &__month-view {
      width: 100%;
      &__days {
        > button {
          width: 14.2857%;
        }
      }
      &__weekdays {
        &__weekday {
          font-size: ${(props) =>
            props.calendarSize === "big" ? "1.125rem" : "0.875rem"};
          > abbr {
            /* remove default style for abbr tag (abbreviation) */
            border-bottom: none !important;
            cursor: inherit !important;
            text-decoration: none !important;
          }
        }
      }
    }

    &__tile {
      max-height: 36.4px;
      &--hover {
        background-color: ${DSColors.MintLight05};
        color: ${DSColors.OffBlack};
      }

      &:not(.react-calendar__tile--active):not(:disabled):hover {
        background-color: ${DSColors.Mint};
        border-radius: var(--tile-border-radius);
        color: ${DSColors.white};
      }

      &--now:not(.react-calendar__tile--active) {
        background-color: ${DSColors.white};
        border: solid 1px ${DSColors.Mint};
        color: ${DSColors.Mint};
        border-radius: var(--tile-border-radius);
      }

      &--rangeEnd,
      &--rangeStart {
        background-color: ${DSColors.MintLight05} !important;
        color: ${DSColors.white};
        overflow: visible !important;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          height: 100%;
          width: 100%;
          border-radius: var(--tile-border-radius);
          top: 0;
          right: 0;
          background-color: ${DSColors.Mint};
        }
      }

      &--rangeEnd {
        border-radius: 0px var(--tile-border-radius) var(--tile-border-radius)
          0px;
        color: ${DSColors.white} !important;
      }

      &--rangeStart {
        border-radius: var(--tile-border-radius) 0 0 var(--tile-border-radius);
        color: ${DSColors.white} !important;
      }

      &--rangeBothEnds {
        background-color: ${DSColors.white} !important;
      }

      &--range:not(.react-calendar__tile--rangeBothEnds) {
        background-color: ${DSColors.MintLight05} !important;
        color: ${DSColors.OffBlack};
      }

      &:disabled {
        background-color: ${DSColors.white} !important;
        color: ${DSColors.SGLight02};
      }

      > abbr {
        font-family: "DM Sans";
        font-size: ${(props) =>
          props.calendarSize === "big" ? "1.125rem" : "0.875rem"};
      }
    }
  }
  /* For some reason, could not target weekends in any other ways */
  .react-calendar__month-view__days__day--weekend:not(
      .react-calendar__month-view__days__day--neighboringMonth
    ):not(:disabled) {
    color: ${stylesVariables.OffBlack};
  }

  .react-calendar {
    line-height: 1.125em;
  }

  .react-calendar--doubleView {
    width: 700px;
  }

  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }

  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar button {
    margin-top: ${(props) => (props.calendarSize === "big" ? "1rem" : "0rem")};
    border: 0;
    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: ${(props) =>
      props.calendarSize === "big" ? "2.75rem" : "1.5rem"};
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
  }
`;

const ChevronNext = styled.img`
  transform: rotate(-90deg);
`;
const ChevronPrev = styled.img`
  transform: rotate(90deg);
`;

export const CalendrierJours: React.FC<ICalendrierJoursProps> = ({
  onChange,
  value,
  selectRange,
  view,
  minDate,
  maxDate,
  disabledDates = [],
  disabledSaturdays = false,
  disabledSundays = false,
  calendarSize = "big",
}) => {
  const locale = "fr-FR";
  const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
    // Block day tiles only
    if (view === "month") {
      if (disabledSaturdays) {
        if (date.getDay() === 6) return true;
      }

      if (disabledSundays) {
        if (date.getDay() === 0) return true;
      }

      return disabledDates.some(
        (disabledDate) =>
          date.getFullYear() === disabledDate.getFullYear() &&
          date.getMonth() === disabledDate.getMonth() &&
          date.getDate() === disabledDate.getDate()
      );
    }
    return false;
  };

  return (
    <Wrapper calendarSize={calendarSize}>
      <Calendar
        onChange={onChange}
        defaultValue={value}
        selectRange={selectRange}
        defaultView="month"
        view={view}
        locale={locale}
        formatShortWeekday={(_locale, value) =>
          ["D", "L", "M", "M", "J", "V", "S"][value.getDay()]
        }
        prev2Label={null}
        next2Label={null}
        nextLabel={<ChevronNext src={chevronDown} />}
        prevLabel={<ChevronPrev src={chevronDown} />}
        minDate={minDate}
        maxDate={maxDate}
        tileDisabled={tileDisabled}
        onClickMonth={undefined}
        // To change when developing monthly calendar
        minDetail="month"
        maxDetail="month"
      />
    </Wrapper>
  );
};
